<template>
  <div class="card">
    <div class="bg-blue-light px-1 rounded pb-2">
      <TitleButton
          class="mt-1"
          btnTitle="List"
          :showBtn="true"
          :showAddNew="false"
          :title="headerTitle"
          @onClickCloseButton="navigateToListPage"
      />

      <div class="row mt-2 gy-1">
        <div class="row mt-1">
          <div class="col-sm-4">
            <label class="align-middle">Requester</label>
            <input type="text" class="form-control" :value="user" readonly>
          </div>
          <div v-if="!isRequisitionCreate" class="col-sm-4">
            <label class="align-middle">Requisition No</label>
            <input type="text" class="form-control" :value="formData.requisition_number" readonly>
          </div>
          <div class="col-sm-4">
            <label class="align-middle">Department</label>
            <v-select
                placeholder="Select Department"
                v-model="formData.department_id"
                :options="departments"
                label="name"
                :reduce="name => name.id"
            />
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-sm-4">
            <label class="align-middle">Requisition Date</label>
            <input type="date" class="form-control" v-model="formData.date" readonly>
          </div>
          <div class="col-sm-4">
            <label class="align-middle">Project</label>
            <v-select
                placeholder="Select Project"
                v-model="formData.project_id"
                :options="projects"
                label="name"
                :reduce="name => name.id"
            />
          </div>
          <div class="col-sm-4">
            <label class="align-middle">Business</label>
            <v-select
                placeholder="Select Business"
                v-model="formData.business_id"
                :options="businesses"
                label="name"
                :reduce="name => name.id"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="px-2">
      <ul class="nav nav-tabs" role="tablist">
        <template v-if="isRequisitionCreate">
          <li class="nav-item">
            <a @click="formData.has_item_detail = true" :class="{'active' : formData.has_item_detail}" disabled class="nav-link"
               data-bs-toggle="tab" aria-controls="home" role="tab" aria-selected="true">Item Details</a>
          </li>
          <li class="nav-item">
            <a @click="formData.has_item_detail = false" :class="{'active' : !formData.has_item_detail}" disabled class="nav-link"
               data-bs-toggle="tab" aria-controls="profile" role="tab" aria-selected="false">Accounts Details</a>
          </li>
        </template>

        <template v-else>
          <li class="nav-item">
            <a :class="{'active' : formData.has_item_detail, 'disabled' : !formData.has_item_detail}" class="nav-link"
               data-bs-toggle="tab" aria-controls="home" role="tab" aria-selected="true">Item Details</a>
          </li>
          <li class="nav-item">
            <a :class="{'active' : !formData.has_item_detail, 'disabled' : formData.has_item_detail}" class="nav-link"
               data-bs-toggle="tab" aria-controls="profile" role="tab" aria-selected="false">Accounts Details</a>
          </li>
        </template>
      </ul>
    </div>

    <div class="px-2 mt-2"
          v-show="!isItem"
      >
        <AddItemDetails
          class="mb-2"
          :products="products"
          :accountHeads="accountHeads"
          v-for="(item, index) in formData.account_details"
          :key="index"
          :index="index"
          :item="item"
          :isItem="isItem"
          :vatRate="vatRate"
          :isRequisitionCreate="isRequisitionCreate"
          :requisitionDate="formData.date"
          :projectId="formData.project_id"
          :warehouseId="business_location_id"
          @onClose="onClose"
        />
      </div>
    <div class="px-2 mt-2" 
      v-show="isItem"
      >
      <AddItemDetails
          class="mb-2"
          :products="products"
          :accountHeads="accountHeads"
          v-for="(item, index) in formData.item_details"
          :key="index"
          :index="index"
          :item="item"
          :isItem="isItem"
          :vatRate="vatRate"
          :isRequisitionCreate="isRequisitionCreate"
          :requisitionDate="formData.date"
          :projectId="formData.project_id"
          :warehouseId="business_location_id"
          @onClose="onClose"
      />
    </div>
    <div class="row me-1 mt-3 px-2">
      <div class="col-12 col-sm-4 col-lg-8 mb-1 mb-sm-0">
        <AddButton title="Add line" @onClickAdd="onClickAdd"/>
      </div>
    </div>

    <div class="p-1">
      <div class="row mt-3">
        <div class="col-12">
          <div class="mb-2">
            <label for="attachment" class="form-label fw-bold">Attachment</label>
            <BlobFileViewer :attachments="formData.attachments" />
            <FileUpload
                :is-drag-active="true"
                button-text="Upload Documents"
                v-model="formData.attachments"
            >
            </FileUpload>
          </div>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-12">
          <label for="note" class="form-label fw-bold">Memo</label>
          <textarea v-model="formData.description" placeholder="Memo" class="form-control" rows="2" id="description"></textarea>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-7"></div>
        <div class="col-5">
          <div class="row mt-1">
            <div class="col-md-4 align-middle">SubTotal :</div>
            <div class="col-md-8">
              <input class="form-control text-end" :value="subTotal" readonly>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-md-4 align-middle">VAT :</div>
            <div class="col-md-8">
              <input class="form-control text-end" :value="totalVAT" readonly>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-md-4 align-middle">Total :</div>
            <div class="col-md-8">
              <input class="form-control text-end" :value="total" readonly>
            </div>
          </div>
        </div>
      </div>
    </div>

     <div class="mt-2 d-flex justify-content-start justify-content-sm-center px-2 gap-1 flex-wrap pb-2">
      <hr class="hr-full-width mb-1">
      <button :disabled="! shouldSave || saveButtonLoader || saveNewButtonLoader" @click="handleSubmit(true)"
              class="btn btn-primary">
        Save
      </button>

      <button v-if="isRequisitionCreate" :disabled="saveButtonLoader || saveNewButtonLoader" @click="handleSubmit(false)"
              class="btn btn-primary">Save & New
      </button>
      <button @click="navigateToListPage" class="btn btn-outline-secondary">Cancel</button>
      <button
          @click="$refs.viewApprovalMatrix.updateShowApprovalMatrix(true)"
          class="btn btn-warning"
          v-if="formData.status && formData.status !== 'pending'"
      >
          Show More
      </button>
    </div>
    <view-approval-matrix-details
      :workflowDetails="workflowDetails"
      :approvalLogs="formData.workflow_approval_logs ?? []"
      ref="viewApprovalMatrix"
    />
    <GlobalLoader/>
  </div>
</template>
<script setup>
import {inject, ref, onMounted, computed, watch} from 'vue';
import {useRoute, useRouter} from "vue-router";
import { useStore } from 'vuex';
import TitleButton from '@/components/atom/TitleButton'
import AddItemDetails from "@/components/molecule/procurement/requisition/AddItemDetails"
import AddButton from "@/components/atom/AddButton"
import FileUpload from "@/components/atom/FileUpload";
import BlobFileViewer from "@/components/atom/BlobFileViewer";
import handleRequisitions from "@/services/modules/procurement/requisition";
import handleProjects from "@/services/modules/procurement/project";
import handleDepartments from "@/services/modules/procurement/organization-settings/department";
import handlePurchase from '@/services/modules/purchase'
import handleCBusinesses from '@/services/modules/businesses';
import {vatRate} from '@/data/inventory.js'
import ViewApprovalMatrixDetails from "@/components/molecule/approval-matrix/ViewApprovalMatrixDetails.vue";
import handleWorkflow from "@/services/modules/approvalMatrix/workflowMatrix";

const router = useRouter()
const route = useRoute()
const showError = inject('showError')
const showSuccess = inject('showSuccess')
const store = useStore()

const today = new Date().toISOString().split('T')[0];

const {fetchProductList, fetchAccountHead, ...rest} = handlePurchase()
const {fetchProjects, fetchAccountsAndItemsByProjectId} = handleProjects();
const {fetchDepartmentList} = handleDepartments();
const {fetchBusinessList} = handleCBusinesses();
const {
  fetchRequisitionNumber, 
  createNewRequisition,
  updateRequisitionData,
  fetchSingleRequisition,
} = handleRequisitions();
const {fetchWorkflowFromPageId} = handleWorkflow()

let saveButtonLoader = ref(false)
let saveNewButtonLoader = ref(false)
let projects = ref([])
let businesses = ref([])
let products = ref([])
let accountHeads = ref([])
let departments = ref([])
const workflowDetails = ref({});
const viewApprovalMatrix = ref(null);
let formData = ref({
  requisition_number: null,
  department_id: null,
  date: null,
  project_id: null,
  project_budget_ref: null,
  business_id: null,
  subtotal_amount: 0.00,
  vat_amount: 0.00,
  total_amount: 0.00,
  has_item_detail: true,
  description: '',
  attachments: [],
  account_details: [
    {
      account_head_id: null,
      amount: null,
      vat: 0,
      vat_amount: null,
      total_amount: null,
      expected_delivery_date: today,
      description: null
    }
  ],
  item_details: [
    {
      product_id: null,
      quantity: null,
      stock_note: null,
      rate: 0,
      amount: null,
      vat: 0,
      vat_amount: null,
      total_amount: null,
      expected_delivery_date: today,
      description: null,
      stock:0
    }
  ],
});

let backupFormData = ref({
  requisition_number: null,
  department_id: null,
  date: new Date().toISOString().split('T')[0],
  project_id: null,
  project_budget_ref: null,
  business_id: null,
  subtotal_amount: 0.00,
  vat_amount: 0.00,
  total_amount: 0.00,
  has_item_detail: true,
  description: '',
  attachments: [],
  account_details: [
    {
      account_head_id: null,
      amount: null,
      vat: 0,
      vat_amount: null,
      total_amount: null,
      stock_note: null,
      total_boq_qty: null,
      remaining_boq_qty: null,
      expected_delivery_date: today,
      description: null
    }
  ],
  item_details: [
    {
      product_id: null,
      quantity: null,
      stock_note: null,
      total_boq_qty: null,
      remaining_boq_qty: null,
      rate: 0,
      amount: null,
      vat: 0,
      vat_amount: null,
      total_amount: null,
      expected_delivery_date: today,
      description: null,
      stock:0
    }
  ],
});
let headerTitle   = ref('Add New Requisition')
let isRequisitionCreate = ref(true);
let requisitionEditData = ref({})
const business_location_id = ref(0);

function navigateToListPage() {
  delete route.query.requisitionId;
  router.push({name: `requisition-list`, params: route.params, query: route.query});
}

const onChangeProject = (projectId) => {
  business_location_id.value = 0;
  if(!projectId) return;

  const project = projects.value.find(item => item.id === projectId);
  if(project.business_location_id){
    business_location_id.value = project.business_location_id
  }
}

function handleSubmit(redirect=false) {
    if (isRequisitionCreate.value){
      createRequisition(redirect);
    }else{
      updateRequisition();
    }
}

function validate(){
    const item = formData.value.item_details.find(item => !item.quantity || !item.rate)
    
    if(!item) return true;

    if(!item.quantity){
      showError('Quantity is required.');
      return false;
    }
    
    return true;
}

function createRequisition(redirect){

  if(isItem.value && !validate()) return;

  if(redirect) {
    saveButtonLoader.value = true
  } else {
    saveNewButtonLoader.value = true
  }

  let copyFormData = new FormData();

  copyFormData.append("requisition_number",formData.value.requisition_number);
  copyFormData.append("date", new Date().toISOString().split('T')[0]);
  copyFormData.append("description",formData.value.description);
  copyFormData.append("project_id", filterData(formData.value.project_id));
  copyFormData.append("department_id",filterData(formData.value.department_id));
  copyFormData.append("business_id",filterData(formData.value.business_id));
  copyFormData.append("has_item_detail",formData.value.has_item_detail ? 1 : 0);
  copyFormData.append("subtotal_amount",subTotal.value);
  copyFormData.append("vat_amount",totalVAT.value);
  copyFormData.append("total_amount",total.value);
  copyFormData.append("page_id",route.params.pageId);

  if(isItem.value) {
    copyFormData.append("item_details", JSON.stringify(formData.value.item_details));
  }else {
    copyFormData.append("account_details", JSON.stringify(formData.value.account_details));
  }

  if(formData.value.attachments) {
    for(let i=0; i<formData.value.attachments.length; i++) {
      let key = `attachments[${i}]`;
      let value = formData.value.attachments[i];
      copyFormData.append(key, value);
    }
  }

  createNewRequisition(copyFormData, getQuery())
      .then(res => {
        saveButtonLoader.value = false;
        saveNewButtonLoader.value = false;
        if(res.status) {
          showSuccess(res.message)
          if(redirect) navigateToListPage()
          resetForm()
        }
        if(!res.status) {
          showError(res.message)
        }
      })
      .catch(err=> {
        saveButtonLoader.value = false;
        saveNewButtonLoader.value = false;
        (err)
      })
}

function updateRequisition(){
  saveButtonLoader.value = true

  let copyFormData = new FormData();
  let previous_file_ids = [];

  copyFormData.append("requisition_number",formData.value.requisition_number);
  copyFormData.append("date",formData.value.date);
  copyFormData.append("description",formData.value.description);
  copyFormData.append("project_id", filterData(formData.value.project_id));
  copyFormData.append("department_id",filterData(formData.value.department_id));
  copyFormData.append("business_id",filterData(formData.value.business_id));
  copyFormData.append("_method", "patch")
  copyFormData.append("has_item_detail",formData.value.has_item_detail ? 1 : 0);
  copyFormData.append("subtotal_amount",subTotal.value);
  copyFormData.append("vat_amount",totalVAT.value);
  copyFormData.append("total_amount",total.value);
  copyFormData.append("page_id",route.params.pageId);

  if(isItem.value) {
    copyFormData.append("item_details", JSON.stringify(formData.value.item_details));
  }else {
    copyFormData.append("account_details", JSON.stringify(formData.value.account_details));
  }

  if(formData.value.attachments) {
    for(let i=0; i<formData.value.attachments.length; i++) {
      if(typeof formData.value.attachments[i] === 'object' && formData.value.attachments[i].hasOwnProperty('id')) {
        previous_file_ids.push(formData.value.attachments[i].id)
      } else {
        let key = `attachments[${i}]`;
        let value = formData.value.attachments[i];
        copyFormData.append(key, value);
      }
    }

    if(previous_file_ids.length) {
      copyFormData.append('previous_file_ids', JSON.stringify(previous_file_ids))
    }
  }

  updateRequisitionData(copyFormData,route.query.requisitionId, getQuery())
      .then(res => {
        saveButtonLoader.value = false
        if(res.status) {
          showSuccess(res.message)
          navigateToListPage();
          resetForm();
        }
        if(!res.status) {
          showError(res.message)
        }
      })
      .catch(err=> {
        saveButtonLoader.value = false;
        (err)
      })
}

function onClose(index) {
  if(isItem.value) {
    formData.value.item_details.splice(index, 1)
  } else {
    formData.value.account_details.splice(index, 1)
  }
}

function onClickAdd() {
  if(isItem.value) {
    formData.value.item_details.push({
      product_id: null,
      quantity: null,
      stock_note: null,
      rate: 0,
      amount: null,
      vat: 0,
      vat_amount: null,
      total_amount: null,
      expected_delivery_date: today,
      description: null,
      stock:0
    });
  } else {
    formData.value.account_details.push({
      account_head_id: null,
      amount: null,
      vat: 0,
      vat_amount: null,
      total_amount: null,
      expected_delivery_date: today,
      description: null
    });
  }
  
}

function getQuery() {
  return `?company_id=${route.params.companyId}`;
}

function filterData(data){
  return (typeof data === 'undefined' || data === null) ? "" : data;
}

function setFormData() {
  if (isRequisitionCreate.value) {
    formData.value.date     = new Date().toISOString().split('T')[0];
  } else {
    headerTitle.value          = 'Update Requisition';
    formData.value.requisition_number   = requisitionEditData.value.requisition_number;
    formData.value.date     = new Date(requisitionEditData.value.date).toISOString().split('T')[0];
    formData.value.description = requisitionEditData.value.description;
    formData.value.department_id = requisitionEditData.value.department_id;
    formData.value.project_id = requisitionEditData.value.project_id;
    formData.value.business_id = requisitionEditData.value.business_id;
    formData.value.has_item_detail = requisitionEditData.value.has_item_detail;
    formData.value.attachments = requisitionEditData.value.attachments;
    formData.value.status = requisitionEditData.value.status;
    formData.value.workflow_approval_logs = requisitionEditData.value.workflow_approval_logs;
    formData.value.account_details = requisitionEditData.value.has_item_detail == 0
                                     ? requisitionEditData.value.account
                                     : [{
                                          account_head_id: null,
                                          amount: null,
                                          vat: null,
                                          vat_amount: null,
                                          total_amount: null,
                                          expected_delivery_date: today,
                                          description: null
                                        }];
    formData.value.item_details = requisitionEditData.value.has_item_detail == 1
                                     ? requisitionEditData.value.general
                                     : [{
                                        product_id: null,
                                        quantity: null,
                                        rate: 0,
                                        amount: null,
                                        vat: null,
                                        vat_amount: null,
                                        total_amount: null,
                                        expected_delivery_date: today,
                                        description: null,
                                        stock:0
                                      }];
  }
}

function resetForm(){
  formData.value = backupFormData.value;
}

async function getAccountsAndItemsByProjectId() {
  const res = await fetchAccountsAndItemsByProjectId(formData.value.project_id, getQuery());

  if (res.status) {
    accountHeads.value = res.data.accounts;
    products.value = res.data.items;
  }

  if (! res.status) {
    accountHeads.value = [];
    products.value = [];
  }
}

async function getDefaultAccountsAndItems() {
  const productRes = fetchProductList(getQuery());
  const accountHeadRes = fetchAccountHead(getQuery());

  await Promise.all([
    productRes.then(res=> {
      if(res.data) products.value = res.data
    }),
    accountHeadRes.then(res=> {
      if(res.data) accountHeads.value = res.data
    }),
  ]);
}

const isItem = computed(() => {
  return !!formData.value.has_item_detail
})

const user = computed(() => {
  return store.state.user.user.name;
})

const subTotal = computed(() => {
    let subTotal=0
    if(formData.value.has_item_detail) {
        formData.value.item_details.map(item => {
            subTotal += parseInt(item.quantity * item.rate)
        })
    }
    if(!formData.value.has_item_detail) {
        formData.value.account_details.map(item => {
            if(item.amount) {subTotal += parseInt(item.amount)}
        })
    }
    
    return subTotal
})

const totalVAT = computed(() => {
    let vat=0
    if(formData.value.has_item_detail) {
        formData.value.item_details.map(item => {
            if(item.vat) {
                vat += parseInt((item.vat/100) * item.quantity * item.rate)
            }
        })
    }
    if(!formData.value.has_item_detail) {
        formData.value.account_details.map(item => {
            if(item.vat) {
                vat += parseInt((item.vat/100) * item.amount)
            }
        })
    }
    
    return vat.toFixed(2)
})

const total = computed(() => {
    return parseInt(subTotal.value) + parseInt(totalVAT.value)
})

const projectId = computed(() => {
  return formData.value.project_id
})

const shouldSave = computed( () => {
    if(! formData.value.status) {
        return true;
    }

    return ['pending', 'returned'].includes(formData.value.status);
})

onMounted(async () => {
  const projectsRes = fetchProjects(getQuery());
  const businessRes = fetchBusinessList(getQuery());
  const departmentListRes = fetchDepartmentList(getQuery());
  const workflowQuery = getQuery() + `&approval_list_page_id=${route.params.pageId}`;

  if (route.query.requisitionId !== undefined && route.query.requisitionId !== null){
    isRequisitionCreate.value = false;
  }

  await Promise.all([
    projectsRes.then(res => {
      if(res.data) projects.value = res.data;
    }),
    businessRes.then(res => {
      if(res.data) businesses.value = res.data;
    }),
    departmentListRes.then(res=> {
       if(res.data) departments.value = res.data

       if (isRequisitionCreate.value){
         const requisitionNumberRes = fetchRequisitionNumber(getQuery());
         requisitionNumberRes.then(res => {
           formData.value.requisition_number = res;
           setFormData();
         })
       }else{
         const fetchRequisition = fetchSingleRequisition(route.query.requisitionId, getQuery());
         fetchRequisition.then(res => {
           requisitionEditData.value = res.data;
           setFormData();
         }),
         fetchWorkflowFromPageId(workflowQuery).then(res => {
             if(res.data) workflowDetails.value = res.data
         })
       }
    }),
  ]);

  await getDefaultAccountsAndItems();
})

watch(isItem, (newValue, oldValue) => {
    if (isRequisitionCreate.value){
      if(!newValue) {
        delete formData.value.item_details
        formData.value.account_details = [{
          account_head_id: null,
          amount: null,
          vat: 0,
          vat_amount: null,
          total_amount: null,
          expected_delivery_date: today,
          description: null,
          stock:0
        }]
      }
      if(newValue) {
        delete formData.value.account_details
        formData.value.item_details = [{
          product_id: null,
          quantity: null,
          rate: 0,
          amount: null,
          vat: 0,
          vat_amount: null,
          total_amount: null,
          expected_delivery_date: today,
          description: null
        }]
      }
    }
})

watch(projectId, (newValue, oldValue) => {
  
  if (newValue !== null){
    getAccountsAndItemsByProjectId();
  }else{
    getDefaultAccountsAndItems();
  }

  if (oldValue !== null){
    formData.value.item_details = [{
      product_id: null,
      quantity: null,
      rate: 0,
      amount: null,
      vat: null,
      vat_amount: null,
      total_amount: null,
      expected_delivery_date: today,
      description: null,
      stock:0
    }];
  }

  onChangeProject(newValue);
})
</script>